import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({

    cardProfile: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: '8px',

      [StyleResponsive.onDesktop]: {
        marginTop: '36px',
        minHeight: '140px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        border: '1px solid #DCDCDC',
      },

      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '230px',
        boxShadow: 'none',
      },

      '&.topbar': {
        height: '480px',
      },
    },

    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: '15px',
      borderRadius: '8px',
      width: '100%',
    },

    profileInformation: {
      [StyleResponsive.onDesktop]: {
        marginLeft: '24px',
      },

      [StyleResponsive.onMobile]: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    btnWhite: {
      height: '40px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',

      [StyleResponsive.onMobile]: {
        marginTop: '15px',
      },

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },

      '&.password': {
        marginTop: '20px',
        fontSize: '12px',
        width: '100%',
        [StyleResponsive.onMobile]: {
          width: '194px',
        },
      },
    },

    boxNumbersProfile: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '500px',
      marginBottom: '24px',

      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },

    boxNumbersBusiness: {
      width: '100%',
      minWidth: '300px',
      padding: '0px 20px 18px',

      [StyleResponsive.onMobile]: {
        maxWidth: '400px',
      },

      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },

    btnGreen: {
      background: theme.palette.primary.main,
      color: '#FFFFFF',

      '&.consulta': {
        '&:hover': {
          background: theme.palette.primary.light,
        },
        [StyleResponsive.onDesktop]: {
          minWidth: '90px',
        },
        [StyleResponsive.onMobile]: {
          width: '100%',
          maxWidth: '425px',
          margin: 'auto',
        },

      },

      '&.save': {
        width: '185px',
        height: '42px',
        '&:hover, &$selected, &$selected:hover ': {
          background: theme.palette.primary.light,
          color: '#FFFFFF',
        },
        '&:disabled': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey['500'],
        },
      },
    },

    boxDots: {
      backgroundColor: '#FFFFFF',
      marginTop: '12px',

      '& .MuiMobileStepper-dot': {
        width: '12px',
        height: '12px',
        margin: '0 8px 14px',
      },
    },

    disabled: {},

    btnBlue: {
      color: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      '&:hover, &$selected, &$selected:hover ': {
        background: theme.palette.secondary.main,
        color: '#FFFFFF',
      },

      '&.back': {
        '&:hover': {
          background: theme.palette.background.default,
          color: theme.palette.secondary.main,
        },
      },

    },

    btnEditNotifications: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      lineHeight: '16px',

      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },

    buttonDelete: {
      color: theme.palette.error.main,
      width: '180px',
      height: '24px',
      display: 'flex',
      alignItems: 'flex-end',
      marginTop: '37px',

      '&:hover, &$selected, &$selected:hover ': {
        backgroundColor: '#FFFFFF',
        color: theme.palette.error.main,
      },
    },

    buttonUnfollow: {
      height: '40px',
      border: `2px solid ${theme.palette.error.main}`,
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.error.main,

      [StyleResponsive.onMobile]: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
      },

      [StyleResponsive.onDesktop]: {
        minWidth: '127px',
      },

      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },

    },


    cardInformation: {
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      display: 'flex',
      marginTop: '15px',

      [StyleResponsive.onDesktop]: {
        minHeight: '215px',
        padding: '8px 27px 12px 22px',
      },

      [StyleResponsive.onMobile]: {
        minHeight: '150px',
        padding: '8px 15px 12px 15px',
      },

    },

    boxInformation: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%',

      [StyleResponsive.onDesktop]: {
        maxWidth: '700px',
      },

    },

    information: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: '16px 0 16px',
    },

    boxConsultas: {

      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      border: `2px solid ${theme.palette.primary.main}`,
      boxSizing: 'border-box',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',

      [StyleResponsive.onDesktop]: {
        minHeight: '50px',
        margin: '16px 0px',
      },

      [StyleResponsive.onMobile]: {
        margin: '16px 10px',
        padding: '10px 16px',
      },


    },

    boxMainHistory: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%'
    },

    cardHistorial: {

      display: 'flex',
      minHeight: '150px',
      marginTop: '21px',
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '22px 22px 22px 28px',
      justifyContent: 'space-evenly',

      [StyleResponsive.onMobile]: {
        padding: '18px 14px 0 22px',
      },
    },

    boxHistorial: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: '8px',
      padding: '8px 18px 16px 18px',
      marginLeft: '8px',
      marginBottom: '18px',
      width: '100%',

      [StyleResponsive.onDesktop]: {
        minHeight: '87px',
      },

      [StyleResponsive.onMobile]: {
        minHeight: '100px',
        marginBottom: '0',
      },

    },

    iconHistorial: {
      width: '28px',
      height: '28px',
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderRadius: '4px',
      marginTop: '4px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& .MuiSvgIcon-root': {
        width: '0.75em',
      },
    },

    divider: {
      width: '2px',
      height: '100%',
      background: '#26A65B',
      margin: '5px 0px',
      borderRadius: '4px',
    },


    boxMenu: {
      height: '36px',
      boxSizing: 'border-box',

      '& .MuiTabs-root , &  .MuiTab-root': {
        minHeight: '36px',
      },

      '& .MuiTabs-indicator , &  .MuiTab-indicator': {
        top: '33px',
      },

      [StyleResponsive.onDesktop]: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #DCDCDC',
        marginBottom: '16px',
      },

      [StyleResponsive.onMobile]: {
        background: '#F8F8F8',
        borderBottom: '1px solid #3A3A3A',
      },
    },

    editInformation: {
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      display: 'flex',

      [StyleResponsive.onDesktop]: {
        minHeight: '770px',
        padding: '38px 19px 18px 36px',

      },
      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        padding: '38px 16px 28px 16px',
      },

    },

    avatar: {
      width: '172px',
      height: '172px',
      borderRadius: '8px',
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:hover, &$selected': {
        background: theme.palette.primary.light,
      },

    },


    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },

      '&.password': {
        marginBottom: '16px',
      },
    },

    textfield: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },


    boxCategory: {
      minWidth: '385px',
      minHeight: '40px',
      background: '#FFFFFF',
      border: '1px solid #3A3A3A',
      boxSizing: 'border-box',
      borderRadius: '6px',

      marginBottom: '8px',
      display: 'flex',

      padding: '12px 14px 11px 20px',

    },

    boxUser: {
      minHeight: '97px',
      backgroundColor: '#FFFFFF',
      marginTop: '11px',
      border: '1px solid #FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '12px 12px 9px 12px',

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',

      [StyleResponsive.onMobile]: {
        minHeight: '65px',
      },
    },


    mainBox: {
      [StyleResponsive.onDesktop]: {
        display: 'flex',
        margin: '34px 0 5px 0',
      },
      [StyleResponsive.onMobile]: {
        margin: '14px 0 60px 0',
      },
    },

    boxSelected: {

      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '19px',
      width: '100%',

      [StyleResponsive.onMobile]: {
        justifyContent: 'flex-end',
      },
    },

    imageProfile: {
      '&:hover': {
        filter: 'blur(2px)',
      },
      '&:hover + $avatarIcon': {
        opacity: 1,
      },
    },


    avatarIcon: {
      width: '65px',
      height: '65px',
      position: 'absolute',
      color: '#FFFFFF',
      opacity: 0,
      '&:hover ': {
        opacity: 1,
      },
      '&:hover + $imageProfile': {
        filter: 'blur(2px)',
      },
    },


    modalDelete: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '555px',
      minHeight: '162px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      padding: '16px 19px',

      [StyleResponsive.onMobile]: {
        width: '100%',
        height: '100%',
      },


    },

    boxBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey['500'] ,
        color: theme.palette.grey['550'] ,
      }
    },

    deleteAccount: {
      color: '#FFFFFF',
      backgroundColor: theme.palette.error.main,

      '&:hover, &$selected, &$selected:hover ': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },
    },

    followCard: {
      height: '135px',
      padding: '18px 16px',
      marginBottom: '8px',
      background: '#FFFFFF',
      border: '1px solid #828282',
      boxSizing: 'border-box',
      borderRadius: '8px',
    },

    titleNotifications: {

      [StyleResponsive.onDesktop]: {
        marginLeft: '39px',
      },
    },

    followers: {
      minWidth: '116px',
      height: '48px',

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
      },
    },

    boxChip: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiChip-root': {
        maxWidth: 'none',
      },
    },

    chip: {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      borderRadius: '6px',
      padding: '12px 4px',
      color: theme.palette.primary.main,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      marginLeft: '5px',
      marginBottom: '5px',
    },

    titleCard: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 15px',
      height: '33px',
      background: alpha(theme.palette.ticket.main, 0.5),
      fontSize: theme.typography.subtitle1.fontSize,

      width: '100%',
    },

    boxConnect: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [StyleResponsive.onDesktop]: {
        marginBottom: '10px',
      },
      [StyleResponsive.onMobile]: {
        marginBottom: '8px',
      },
    },

    messageAssociate: {
      textAlign: 'center',
      color: theme.palette.primary.main,
    },

    buttons: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '170px',
      marginBottom: '18px',
      marginRight: '10px',
      justifyContent: 'flex-start',
      padding: '4px 6px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '120px',
        minHeight: '42px',
        marginBottom: '10px',
        marginRight: '10px',
      },

      '&.facebook': {
        border: `2px solid ${theme.palette.secondary.main}`,
        color: `${theme.palette.secondary.main}`,
      },

      '&.google': {
        border: `2px solid ${theme.palette.error.main}`,
        color: `${theme.palette.error.main}`,
      },

    },

    socialIcon: {
      [StyleResponsive.onMobile]: {
        width: '33px',
        height: '33px',
      },
    },

    textButton: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      [StyleResponsive.onDesktop]: {
        marginLeft: '16px',
      },
      [StyleResponsive.onMobile]: {
        marginLeft: '3px',
      },
    },

    formHelperText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
      marginBottom: '10px',
    },

    location: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',

      '& > .location': {
        width: '100%',
        padding: '12px 8px',
        position: 'absolute',
        zIndex: 100,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'hidden',

        '& > .option': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 0px',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '& > .iconLocation': {
            color: theme.palette.primary.main,
          },
        },
      },
    },

    inputMessage: {
      fontSize: '12px',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },

    inputMessageIcon: {
      color: theme.palette.primary.main,
      height: '16px',
      width: '16px',
    },

  }));
